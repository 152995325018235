<template>
  <div>
    <div :class="[{ativo: state}, 'servicos']" @click.prevent="addService($event, service)">
      <span class="categoria">
        <strong>{{ service.name }}</strong> - <span class="valor">{{ service.value | value }}</span>
        <span v-if="service.maximum_quantity" class="quantidade">
          <select class="form-select" v-model="quantity">
            <option v-for="i in service.maximum_quantity" :value="i">
              {{ padStart(i, 2, '0') }} {{ plural(i, 'Sessão', 'Sessões') }}
            </option>
          </select>
        </span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "Service",
  props: {
    service: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      state: false,
      quantity: 1,
    }
  },
  filters: {
    value(value) {
      return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    },
  },
  methods: {
    addService(event, service) {
      let target = event.target;

      if (target.type === 'select-one') {
        service.quantity = parseInt(this.quantity);
        this.$emit('update-quantity', service)
      } else {
        this.state = !this.state;
        this.$emit('toggle-service', service)
      }

      if (!target.classList.contains('servicos')) {
        target = target.closest('.servicos');
      }

      target.classList.toggle('ativo');
    },
    padStart: (variable, length, string) => variable.toString().padStart(length, string),
    plural: (quantity, singular, plural) => quantity === 1 ? singular : plural
  },
}
</script>

<style scoped>

</style>