<template>
  <form id="formSubscription" method="post" role="form">
    <div class="row">
      <div class="col-md-8">
        <h3 class="column-title">
          Dados do Atleta
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.name.validation }">
              <label for="name">Nome completo*</label>
              <input v-model="store.buyer.name.value" class="form-control" id="name" type="text"
                     required>
              <span class="help-block">
                {{ store.buyer.name.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.gender.validation }">
              <label for="email">Genero*</label>
              <select v-model="store.buyer.gender.value" class="form-control form-select" id="gender" required>
                <option> Selecione</option>
                <option value="N">
                  Não binário
                </option>
                <option value="F">
                  Feminino
                </option>
                <option value="M">
                  Masculino
                </option>
              </select>
              <span class="help-block">
                {{ store.buyer.gender.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.birth_date.validation }">
              <label for="email">Data de nascimento*</label>
              <input v-model="store.buyer.birth_date.value" class="form-control" id="birth_date" type="text"
                     data-format="date" required>
              <span class="help-block">
                {{ store.buyer.birth_date.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.cpf.validation }">
              <label for="cpf">CPF*</label>
              <input v-model="store.buyer.cpf.value" class="form-control" data-format="cpf" id="cpf" required>
              <span class="help-block">
                {{ store.buyer.cpf.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.email.validation }">
              <label for="email">E-mail*</label>
              <input v-model="store.buyer.email.value" class="form-control" id="email"
                     placeholder="" type="email" required>
              <span class="help-block">
                {{ store.buyer.email.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.phone.validation }">
              <label for="email">
                Telefone*
              </label>
              <input v-model="store.buyer.phone.value" class="form-control form-control-phone" id="phone"
                     data-format="phone"
                     placeholder="" type="text" required>
              <span class="help-block">
                {{ store.buyer.phone.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.address.validation }">
              <label for="email">Rua, número - bairro*</label>
              <input v-model="store.buyer.address.value" class="form-control" id="address"
                     placeholder="" type="text" required>
              <span class="help-block">
                {{ store.buyer.address.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.city.validation }">
              <label for="email">Cidade*</label>
              <input v-model="store.buyer.city.value" class="form-control" id="city"
                     placeholder="" type="text" required>
              <span class="help-block">
                {{ store.buyer.city.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.state.validation }">
              <label for="email">Estado*</label>
              <select v-model="store.buyer.state.value" class="form-control form-select" id="state" required>
                <option> Selecione</option>
                <option v-for="state in store.states" :value="state.uf">
                  {{ state.name }}
                </option>
              </select>
              <span class="help-block">
                {{ store.buyer.state.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.instagram.validation }">
              <label for="email">Instagram</label>
              <input v-model="store.buyer.instagram.value" class="form-control" id="instagram" type="text">
              <span class="help-block">
                {{ store.buyer.instagram.validation }}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" v-bind:class="{ 'has-error': store.buyer.facebook.validation }">
              <label for="phone">Facebook</label>
              <input v-model="store.buyer.facebook.value" class="form-control" id="facebook" type="text">
              <span class="help-block">
                {{ store.buyer.facebook.validation }}
              </span>
            </div>
          </div>
        </div>
        <br>
        <br>
        <h3 class="column-title">
          Selecione as Categorias
        </h3>
        <hr style="margin: 20px 0;">
        <div class="row">
          <div class="col-md-12">
            <div v-if="this.store.event.subscriptionWarning">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse">
                      Observações
                    </a>
                  </h4>
                </div>
                <div id="collapse" class="panel-collapse collapse in">
                  <div class="panel-body">
                    <p v-html="this.store.event.subscriptionWarning">
                    </p>
                  </div>
                </div>
              </div>
              <br>
              <br>
            </div>
            <ListCategories :categories="store.event.categories" @toggle-category="toggleCategory"/>
          </div>
        </div>
        <br>
        <br>
        <ListServices :services="store.event.services" @toggle-service="toggleService"
                      @update-quantity="updateQuantity"/>
      </div>
      <div class="col-md-4">
        <h3 class="column-title">
          Resumo da Inscrição
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Cart :prop-cart="store.cart"/>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-primary fw-600" v-on:click.prevent="submitPix($event)">
            Pagar com PIX
          </button>
          <button class="btn btn-primary fw-600" v-on:click.prevent="submit($event)" v-if="pagSeguroEnabled()">
            Pagar com Cartão
          </button>
          <div class="text-right"><br>
            <p style="color: #ff0000;">
              A inscrição só é <strong>finalizada</strong> após o<br>
              <strong>pagamento ser confirmado</strong>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Swal from 'sweetalert2';
import Axios from 'axios';
import Cart from "./Cart";
import ListCategories from "./ListCategories";
import ListServices from "./ListServices";
import _ from "lodash";

export default {
  name: "FormSubscription",
  components: {
    Cart,
    ListServices,
    ListCategories
  },
  data() {
    return {
      store: {
        states: [
          {name: "Acre", uf: "AC"},
          {name: "Alagoas", uf: "AL"},
          {name: "Amapá", uf: "AP"},
          {name: "Amazonas", uf: "AM"},
          {name: "Bahia", uf: "BA"},
          {name: "Ceará", uf: "CE"},
          {name: "Distrito Federal", uf: "DF"},
          {name: "Espírito Santo", uf: "ES"},
          {name: "Goiás", uf: "GO"},
          {name: "Maranhão", uf: "MA"},
          {name: "Mato Grosso", uf: "MT"},
          {name: "Mato Grosso do Sul", uf: "MS"},
          {name: "Minas Gerais", uf: "MG"},
          {name: "Pará", uf: "PA"},
          {name: "Paraíba", uf: "PB"},
          {name: "Paraná", uf: "PR"},
          {name: "Pernambuco", uf: "PE"},
          {name: "Piauí", uf: "PI"},
          {name: "Rio de Janeiro", uf: "RJ"},
          {name: "Rio Grande do Norte", uf: "RN"},
          {name: "Rio Grande do Sul", uf: "RS"},
          {name: "Rondônia", uf: "RO"},
          {name: "Roraima", uf: "RR"},
          {name: "Santa Catarina", uf: "SC"},
          {name: "São Paulo", uf: "SP"},
          {name: "Sergipe", uf: "SE"},
          {name: "Tocantins", uf: "TO"}
        ],
        buyer: {
          cpf: {
            value: null,
            validation: null
          },
          name: {
            value: null,
            validation: null
          },
          email: {
            value: null,
            validation: null
          },
          phone: {
            value: null,
            validation: null
          },
          address: {
            value: null,
            validation: null
          },
          city: {
            value: null,
            validation: null
          },
          state: {
            value: null,
            validation: null
          },
          birth_date: {
            value: null,
            validation: null
          },
          gender: {
            value: null,
            validation: null
          },
          instagram: {
            value: null,
            validation: null
          },
          facebook: {
            value: null,
            validation: null
          },
        },
        event: {
          id: null,
          name: null,
          services: [],
          categories: [],
          subCategories: [],
          subCategoriesClasses: [],
          subscriptionWarning: null,
          getCategories() {
            return this.categories;
          },
          getServices() {
            return this.services;
          },
        },
        cart: {
          types: {
            service: 'service',
            subCategory: 'subCategory',
            subCategoryClass: 'subCategoryClass',
          },
          listForType: {
            service: 'services',
            subCategory: 'subCategories',
            subCategoryClass: 'subCategoriesClasses'
          },
          items: {
            services: [],
            subCategories: [],
            subCategoriesClasses: [],
          },
          handleCategory(category) {
            this.hasItem(category.type, category)
                ? this.delItem(category.type, category)
                : this.addItem(category.type, category);
          },
          handleService(service) {
            if (this.hasItem(service.type, service)) {
              this.delItem(service.type, service);
            } else {
              if (service.quantity > 1 && service.double > 0) {
                let a = _.clone(service);
                a.quantity = 1;
                a.discount = false;
                this.addItem(a.type, a);
                let b = _.clone(service);
                b.discount = true;
                b.quantity = (b.quantity - 1);
                this.addItem(b.type, b);
              } else {
                service.discount = false;
                this.addItem(service.type, service);
              }
            }
          },
          handleItem(type, item) {
            if (this.hasItem(type, item)) {
              this.delItem(type, item);
            } else {
              this.addItem(type, item);
            }
          },
          getList(type) {
            return this.items[this.listForType[type]];
          },
          hasItem(type, item) {
            return !!this.getList(type).filter((object) => object.id === item.id).length;
          },
          addItem(type, item) {
            this.getList(type).push(item);
          },
          delItem(type, item) {
            this.items[this.listForType[type]] = this.getList(type).filter(function (object) {
              return item.id !== object.id;
            });
          },
        }
      }
    }
  },
  watch: {
    'store.buyer.cpf.value': function () {
      this.store.buyer.cpf.validation = "";
    },
    'store.buyer.name.value': function () {
      this.store.buyer.name.validation = "";
    },
    'store.buyer.email.value': function () {
      this.store.buyer.email.validation = "";
    },
    'store.buyer.phone.value': function () {
      this.store.buyer.phone.validation = "";
    },
    'store.buyer.address.value': function () {
      this.store.buyer.address.validation = "";
    },
    'store.buyer.city.value': function () {
      this.store.buyer.city.validation = "";
    },
    'store.buyer.state.value': function () {
      this.store.buyer.state.validation = "";
    },
    'store.buyer.birth_date.value': function () {
      this.store.buyer.birth_date.validation = "";
    },
    'store.buyer.gender.value': function () {
      this.store.buyer.gender.validation = "";
    },
    'store.buyer.instagram.value': function () {
      this.store.buyer.instagram.validation = "";
    },
    'store.buyer.facebook.value': function () {
      this.store.buyer.facebook.validation = "";
    },
  },
  methods: {
    pagSeguroEnabled() {
      return true;
    },
    toggleCategory(category) {
      this.store.cart.handleCategory(category);
    },
    toggleService(service) {
      this.store.cart.handleService(service)
    },
    updateQuantity(service) {
      this.store.cart.delItem(service.type, service);
      this.store.cart.handleService(service)
    },
    submitPix(event) {
      if (event.target.closest('form').reportValidity()) {
        let that = this,
            cart = that.store.cart,
            buyer = that.store.buyer,
            services = cart.getList(cart.types.service),
            subCategory = cart.getList(cart.types.subCategory),
            subCategoryClass = cart.getList(cart.types.subCategoryClass);
        if (subCategory.length > 0 || subCategoryClass.length > 0 || services.length > 0) {
          Swal.fire({
            title: 'Aguarde...',
            html: 'Estamos gerando a chave para o PIX',
            preConfirm: () => {
              if (confirm('Tem certeza que deseja fechar?')) {
                window.location.href = `${window.location.origin}/eventos/retorno?transaction_id=${window.transactionId}`
              }
            },
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen() {
              Swal.showLoading();
              Axios.post(`/api/events/event/${that.$parent.$data.slug}/subscription-pix`, {
                cpf: buyer.cpf.value,
                name: buyer.name.value,
                email: buyer.email.value,
                phone: buyer.phone.value,
                address: buyer.address.value,
                city: buyer.city.value,
                state: buyer.state.value,
                birth_date: buyer.birth_date.value,
                gender: buyer.gender.value,
                instagram: buyer.instagram.value,
                facebook: buyer.facebook.value,
                services: cart.getList(cart.types.service),
                subCategories: subCategory,
                subCategoriesClasses: subCategoryClass,
              })
                  .then(
                      ({data}) => {
                        window.transactionId = data.transaction_id;
                        Swal.hideLoading()
                        Swal.update({
                          title: 'Dados do PIX',
                          html: `
                            <b>Chave:</b> ${data.key} <button class="btn btn-sm btn-link" onclick="copyTextToClipboard('${data.key}')"><i class="fa fa-copy"></i> </button> <br>
                            <b>Nome Favorecido:</b> ${data.name} <br>
                            <b>Valor:</b> ${data.value}
                            <p>Após o pagamento, enviar o comprovante para o WhatsApp <a href="https://wa.me/5547999541579">+55 47 999.541.579</a></p>
                          `
                        });
                      }
                  )
                  .catch(e => {
                    let errors = e.response.data.errors;

                    _.each(errors, function (error, indice) {
                      _.set(buyer, [indice, 'validation'], error[0])
                    });

                    Swal.fire('Atenção', 'Verifique o formulario!', 'error');
                  });
            }
          });
        } else {
          Swal.fire('Atenção', 'Selecione pelo menos uma categoria ou serviço.', 'warning')
        }
      }
    },
    submit(event) {
      if (event.target.closest('form').reportValidity()) {
        let that = this,
            cart = that.store.cart,
            buyer = that.store.buyer,
            services = cart.getList(cart.types.service),
            subCategory = cart.getList(cart.types.subCategory),
            subCategoryClass = cart.getList(cart.types.subCategoryClass);
        if (subCategory.length > 0 || subCategoryClass.length > 0 || services.length > 0) {
          Swal.fire({
            title: 'Aguarde...',
            html: 'Estamos validando seus dados.',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen() {
              Swal.showLoading();
              Axios.post(`/api/events/event/${that.$parent.$data.slug}/subscription`, {
                cpf: buyer.cpf.value,
                name: buyer.name.value,
                email: buyer.email.value,
                phone: buyer.phone.value,
                address: buyer.address.value,
                city: buyer.city.value,
                state: buyer.state.value,
                birth_date: buyer.birth_date.value,
                gender: buyer.gender.value,
                instagram: buyer.instagram.value,
                facebook: buyer.facebook.value,
                services: cart.getList(cart.types.service),
                subCategories: subCategory,
                subCategoriesClasses: subCategoryClass,
              })
                  .then(function (response) {
                    if (
                        response !== undefined
                        && response.data !== undefined
                        && that.isValidUrl(response.data.url)
                    ) {
                      window.location.href = response.data.url;
                    } else {
                      Swal.fire('Ops', 'Algo deu errado, tente novamente mais tarde.', 'error');
                    }
                  })
                  .catch(e => {
                    let errors = e.response.data.errors;

                    _.each(errors, function (error, indice) {
                      _.set(buyer, [indice, 'validation'], error[0])
                    });

                    Swal.close();
                  });
            }
          });
        } else {
          Swal.fire('Atenção', 'Selecione pelo menos uma categoria ou serviço.', 'warning')
        }
      }
    },
    effectiveValue(category, subCategory, subCategoryClass) {
      if (subCategoryClass !== undefined && subCategoryClass.value) {
        return subCategoryClass.value;
      }
      if (subCategory.value) {
        return subCategory.value;
      }
      if (category.value) {
        return category.value;
      }
    },
    isValidUrl(url) {
      try {
        new URL(url);
      } catch (e) {
        return false;
      }
      return true;
    },
    effectiveDouble(category, subCategory, subCategoryClass) {
      if (subCategoryClass !== undefined && subCategoryClass.double) {
        return subCategoryClass.double;
      }
      if (subCategory.double) {
        return subCategory.double;
      }
      if (category.double) {
        return category.double;
      }
    },
    prepareCategories(categories) {
      let that = this,
          output = [];
      _.each(categories, function (category) {
        _.each(category.subCategories, function (subCategory) {
          if (subCategory.classes.length > 0) {
            _.each(subCategory.classes, function (subCategoryClass) {
              let categoryObject = {
                id: subCategoryClass.id,
                type: 'subCategoryClass',
                value: that.effectiveValue(category, subCategory, subCategoryClass),
                double: that.effectiveDouble(category, subCategory, subCategoryClass),
                quantity: 1,
                categoryName: category.name,
                subCategoryName: subCategory.name,
                subCategoryClassName: subCategoryClass.name,
                subCategoryClassDescription: subCategoryClass.description,
              }

              output.push(categoryObject);
            });
          } else {
            let categoryObject = {
              id: subCategory.id,
              type: 'subCategory',
              value: that.effectiveValue(category, subCategory),
              double: that.effectiveDouble(category, subCategory),
              quantity: 1,
              categoryName: category.name,
              subCategoryName: subCategory.name,
            }

            output.push(categoryObject);
          }
        });
      });
      return output;
    },
    prepareServices(services) {
      let output = [],
          serviceObject = {};
      _.each(services, function (service) {
        serviceObject = {
          id: service.id,
          type: 'service',
          name: service.name,
          value: service.value,
          double: service.double,
          quantity: 1,
          maximum_quantity: service.maximum_quantity,
        }
        output.push(serviceObject);
      });
      return output;
    },
  },
  beforeCreate() {
    Axios.get('/api/events/event/' + this.$parent.$data.slug)
        .then(
            (response) => {
              this.store.event.id = response.data.data.id;
              this.store.event.name = response.data.data.name;
              this.store.event.services = this.prepareServices(response.data.data.services);
              this.store.event.categories = this.prepareCategories(response.data.data.categories);
              this.store.event.subscriptionWarning = response.data.data.subscription_warning;
            }
        );
  }
}
</script>

<style scoped>
.fw-600 {
  font-weight: 600 !important;
}
</style>